exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-additional-coverages-additional-coverages-template-js": () => import("./../../../src/templates/AdditionalCoverages/AdditionalCoveragesTemplate.js" /* webpackChunkName: "component---src-templates-additional-coverages-additional-coverages-template-js" */),
  "component---src-templates-collector-collector-template-js": () => import("./../../../src/templates/Collector/CollectorTemplate.js" /* webpackChunkName: "component---src-templates-collector-collector-template-js" */),
  "component---src-templates-collector-truck-collector-truck-template-js": () => import("./../../../src/templates/CollectorTruck/CollectorTruckTemplate.js" /* webpackChunkName: "component---src-templates-collector-truck-collector-truck-template-js" */),
  "component---src-templates-home-home-template-js": () => import("./../../../src/templates/Home/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-home-template-js" */),
  "component---src-templates-insurance-insurance-template-js": () => import("./../../../src/templates/Insurance/InsuranceTemplate.js" /* webpackChunkName: "component---src-templates-insurance-insurance-template-js" */),
  "component---src-templates-signature-signature-template-js": () => import("./../../../src/templates/Signature/SignatureTemplate.js" /* webpackChunkName: "component---src-templates-signature-signature-template-js" */)
}

